@import url(https://use.typekit.net/nej5njo.css);
@font-face {
  font-family: "AktivGroteskBold";
  src: url("/static/media/Aktiv Grotesk W06 Bold.6cd98f92.ttf");
}
@font-face {
  font-family: "AktivGroteskMedium";
  src: url("/static/media/Aktiv Grotesk W06 Medium.4f315000.ttf");
}
@font-face {
  font-family: "AktivGroteskRegular";
  src: url("/static/media/Aktiv Grotesk W06 Regular.a59a10fc.ttf");
}
@font-face {
  font-family: "InterstateBold";
  src: url(/static/media/Interstate-Bold.b7c3dd38.ttf);
}
@font-face {
  font-family: "InterstateRegular";
  src: url(/static/media/interstate-regular.52801e5c.ttf);
}

#root {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  background: #f5f5f5;
  /* overflow: hidden; */
  min-height: 100vh;
  --pulsating-dot: rgb(8, 198, 5);
}

body {
  margin: 0;
  padding: 0;
}

.ant-drawer-title {
  font-size: 24px !important;
  font-weight: bold !important;
  letter-spacing: 0.13px !important;
  color: #2b2b2b !important;
}
.ant-drawer-header {
  padding: 20px !important;
  border: none !important;
}
.search-container {
  box-shadow: 0px 1px 2px #00000029;
  background-color: white !important;
  min-height: 60px;
  line-height: 60px;
  padding-left: 30px;
  padding-right: 30px;
  background: white;
  padding-bottom: 10px;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 9;
}
.search-input-style {
  height: 40px !important;
  margin-top: 15px !important;
}
.ant-input {
  border: 1px solid #bdbdbd !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  top: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.ant-switch-checked {
  background-color: #2196f3 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #2196f3 !important;
}

.ant-radio-checked .ant-radio-inner::after {
  background-color: #2196f3 !important;
}

.ant-modal-header {
  border: none !important;
  padding-bottom: 0px;
}

.ant-modal-footer {
  border: none !important;
}

.site-checkbox-all-wrapper {
  border-bottom: 1px solid #e9e9e9;
}
.ant-tabs-tab-active {
  color: #1b51cf !important;
  border-bottom-color: #1b51cf !important;
}

.ant-tabs-nav-scroll {
  background-color: white !important;
  padding-top: 18px;
}

#run-list-search-style {
  border-top: none !important;
  border-bottom: none !important;
  border-left: none !important;
  border-color: #e6e6e6 !important;
}

.ant-modal-footer {
  border-top: none !important;
}

.ant-collapse-content {
  background-color: transparent !important;
}

.ant-collapse {
  background-color: transparent !important;
  border: none;
  padding-left: 0px !important;
}

.ant-collapse-header {
  padding-left: 12px !important;
}

.ant-collapse-content-box {
  border: none;
  padding-left: 0px !important;
  /* margin-left: 24px; */
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: #1b51cf !important;
}

.ant-tabs-ink-bar {
  background-color: #1b51cf !important;
}

.table-row {
  cursor: pointer;
}

.your-table table {
  width: auto !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
}

.your-table .ant-table-content {
  overflow-y: scroll;
  max-height: 762px;
}

.your-table .ant-table-wrapper {
  max-height: 80vh;
}

.your-table .ant-table-tbody {
}

.show {
  display: normal;
}

.hide {
  display: none;
}

.ReactVirtualized__Table__headerRow {
  background-color: lightslategray;
  color: #ffffff !important;
  border-bottom: 1px solid lightgray;
}

.ReactVirtualized__Table__headerColumn {
  /* border-right: 1px solid lightgray; */
}

.ReactVirtualized__Table__row {
  border-bottom: 1px solid silver;
}

.ReactVirtualized__Grid {
  height: 620px !important;
}

.ReactVirtualized__Table__Grid {
  height: 620px !important;
}

.loading-skeleton {
  color: gray;
  /* background-color: gray; */
}

.ant-slider-step {
  background: #1b51cf !important;
}

.ant-slider-handle {
  background-color: #1b51cf !important;
  border: none !important;
}

.ant-switch-checked {
  background-color: #1b51cf !important;
}

.ant-switch-checked {
  background-color: #2196f3 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #2196f3 !important;
}

.ant-radio-checked .ant-radio-inner::after {
  background-color: #2196f3 !important;
}

.ant-checkbox .ant-checkbox-inner {
  border-color: #4c4c4c !important;
}

thead tr th:first-child .ant-checkbox-inner::after {
  position: relative !important;
  color: white !important;
  border: none !important;
  background: transparent !important;
  -webkit-transform: none !important;
          transform: none !important;
  content: "-" !important;
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  margin: 0 auto !important;
}

thead tr th:first-child .ant-checkbox-checked .ant-checkbox-inner::after {
  color: white !important;
  content: "-" !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
  background: #2196f3 !important;
}

table.fixed {
  table-layout: fixed !important;
}

td.truncated {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.ant-table-thead
  > tr
  > th
  .ant-table-header-column
  .ant-table-column-sorters
  > .ant-table-column-title {
  white-space: nowrap !important;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  opacity: 1 !important;
}
.ant-table-column-title {
  white-space: nowrap !important;
}

.css-1rq85j {
  max-width: 1900px !important;
}

.ant-dropdown {
  z-index: 1000 !important;
}
.ant-radio-group .ant-radio-group-outline {
  width: 100% !important;
}
.ant-radio-wrapper {
  margin-right: 10px;
  text-align: justify;
}
.ant-radio {
  margin-right: 2px;
}
.ant-pagination {
  display: none !important;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4c4c4c !important;
  opacity: 1 !important; /* Firefox */
  letter-spacing: 0.2px !important;
  font-family: AktivGroteskRegular !important;
}

::-moz-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4c4c4c !important;
  opacity: 1 !important; /* Firefox */
  letter-spacing: 0.2px !important;
  font-family: AktivGroteskRegular !important;
}

:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4c4c4c !important;
  opacity: 1 !important; /* Firefox */
  letter-spacing: 0.2px !important;
  font-family: AktivGroteskRegular !important;
}

::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4c4c4c !important;
  opacity: 1 !important; /* Firefox */
  letter-spacing: 0.2px !important;
  font-family: AktivGroteskRegular !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4c4c4c !important;
  opacity: 1 !important; /* Firefox */
  letter-spacing: 0.2px !important;
  font-family: AktivGroteskRegular !important;
}
.ant-tabs-tab {
  min-width: 120px !important;
  text-align: center !important;
}
.ant-table-fixed-left th {
  padding-top: 68px !important;
}
.ant-table-fixed {
  background-color: #fafafa !important;
}
th[colspan="3"] {
  left: -680px !important;
  position: relative !important;
  z-index: 1 !important;
  background-color: transparent !important;
}
th[colspan="5"] {
  left: -315px !important;
  background-color: transparent !important;
  position: relative !important;
  /* z-index: 1 !important; */
}
th[colspan="4"] {
  left: -260px !important;
  background-color: transparent !important;
  position: relative !important;
  /* z-index: 1 !important; */
}
.ant-table-row-level-0 .level0data {
  display: none !important;
}
/* .ant-table-scroll{
  padding-left: 120px !important;
} */
.css-1fn4u4u-emotion--MenuItem--MenuItem {
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 7.5px !important;
  padding-bottom: 7.5px !important;
}
.css-13kqfjz-emotion--SellerBasicTable--menu {
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 7.5px !important;
  padding-bottom: 7.5px !important;
}

@media screen and (max-width: 270px) {
  .ant-tabs-tab-prev {
    display: none !important;
  }

  .ant-tabs-tab-next {
    display: none !important;
  }

  .ant-tabs-nav-container-scrolling {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

@media screen and (max-width: 400px) {
  .ant-tabs-tab {
    min-width: auto !important;
  }
}

.ant-modal-title {
  font-size: 24px !important;
}

.ant-table-placeholder {
  margin-top: 10px !important;
}

